/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Logo from '../assets/images/logoweb.png.webp';

function Navbar({ children }) {
    return (
        <div className="main-header">
            <div className="logo">
                <img src={Logo} />
            </div>
        </div>
    );
}

export default Navbar;