/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { getDetailJob } from '../../redux/actions';
import Footer from '../../components/Footer';
import RouteAccess from '../../config/RouteAccess';

function index() {
    const dispatch = useDispatch();
    let { slug } = useParams();

    const {
        detail,
        detail_loading,
    } = useSelector(
        ({ jobData }) => jobData,
    );

    const [register_loading, setRegisterLoading] = useState(false);

    const {
        register,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useForm({
        defaultValues: {
            applicant_job_fullname: '',
            applicant_job_email: '',
            applicant_job_nickname: '',
            applicant_job_gender: '',
            applicant_job_pob: '',
            applicant_job_dob: '',
            applicant_job_address: '',
            applicant_job_current_address: '',
            applicant_job_citizenship: '',
            applicant_job_phone: '',
            applicant_job_marital_status: '',
            applicant_job_work_exp_year: '',
            applicant_job_apply_via: '',
            applicant_job_expect_salary: '',
            applicant_job_advantages: '',
            applicant_job_deficiency: '',
            applicant_job_cv: '',
            applicant_job_status: 'new',
        },
    });

    const onSubmit = useCallback(
        data => {
            setRegisterLoading(true)

            const formData = new FormData();

            formData.append('applicant_job_id', data.applicant_job_id);
            formData.append('applicant_job_fullname', data.applicant_job_fullname);
            formData.append('applicant_job_email', data.applicant_job_email);
            formData.append('applicant_job_nickname', data.applicant_job_nickname);
            formData.append('applicant_job_gender', data.applicant_job_gender);
            formData.append('applicant_job_pob', data.applicant_job_pob);
            formData.append('applicant_job_dob', data.applicant_job_dob);
            formData.append('applicant_job_address', data.applicant_job_address);
            formData.append('applicant_job_current_address', data.applicant_job_current_address);
            formData.append('applicant_job_citizenship', data.applicant_job_citizenship);
            formData.append('applicant_job_phone', data.applicant_job_phone);
            formData.append('applicant_job_marital_status', data.applicant_job_marital_status);
            formData.append('applicant_job_work_exp_year', data.applicant_job_work_exp_year);
            formData.append('applicant_job_apply_via', data.applicant_job_apply_via);
            formData.append('applicant_job_expect_salary', data.applicant_job_expect_salary);
            formData.append('applicant_job_cv', data.applicant_job_cv[0]);
            formData.append('applicant_job_advantages', data.applicant_job_advantages);
            formData.append('applicant_job_deficiency', data.applicant_job_deficiency);
            formData.append('applicant_job_status', 'new');

            axios
                .post(`${RouteAccess.POST_APPLY_JOB}`, formData)
                .then(res => {
                    const response = res && res.data ? res.data : {};
                    const { success, message } = response;

                    if (success) {
                        toast.success(message, {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setTimeout(() => {
                            resetForm();
                            setRegisterLoading(false);
                        }, 1500);
                    } else {
                        setRegisterLoading(false);
                        toast.error(message, {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setTimeout(() => {
                            setRegisterLoading(false);
                        }, 1500);
                    }
                })
                .catch(error => {
                    console.log(error);
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setRegisterLoading(false);
                    }, 3000);
                });
        }
    );

    const resetForm = () => {
        resetField("applicant_job_fullname");
        resetField("applicant_job_email");
        resetField("applicant_job_nickname");
        resetField("applicant_job_gender");
        resetField("applicant_job_pob");
        resetField("applicant_job_dob");
        resetField("applicant_job_address");
        resetField("applicant_job_current_address");
        resetField("applicant_job_citizenship");
        resetField("applicant_job_phone");
        resetField("applicant_job_marital_status");
        resetField("applicant_job_work_exp_year");
        resetField("applicant_job_apply_via");
        resetField("applicant_job_expect_salary");
        resetField("applicant_job_cv");
        resetField("applicant_job_advantages");
        resetField("applicant_job_deficiency");
    };

    useEffect(() => {
        dispatch(getDetailJob(slug));
    }, []);

    return (
        <>
            <div className='main-content'>
                <div className="breadcrumb">
                    <NavLink className="btn btn-outline-info btn-icon btn-sm m-1" to={`/detail/${slug}`}>
                        <span className='ul-btn__icon'>
                            <i className='i-Arrow-Back-2'></i>
                        </span>
                        Kembali
                    </NavLink>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className='offset-1 col-md-10 mb-4'>
                    <div className="card">
                        {detail_loading &&
                            <div className="card-body text-center">
                                <div className='spinner spinner-info'></div>
                            </div>
                        }
                        {detail &&
                            <div className="card-body">
                                <h3>Form Lamaran: {detail.job_title}</h3>
                                <form className='mt-5' onSubmit={handleSubmit(onSubmit)}>
                                    <input className='form-control' {...register("applicant_job_id")} defaultValue={detail.job_id} type='hidden'></input>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_fullnafme">Nama Lengkap <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className="form-control form-control-rounded" {...register("applicant_job_fullname", { required: "Nama Lengkap wajib diisi" })} type="text" placeholder="Nama Lengkap" />
                                            {errors.applicant_job_fullname?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_fullname?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_email">Email <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className="form-control form-control-rounded" {...register("applicant_job_email", { required: "Email wajib diisi" })} aria-invalid={errors.applicant_job_email ? "true" : "false"} type="email" placeholder="Email" />
                                            {errors.applicant_job_email?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_email?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_phone">Nomor Telepon <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className="form-control form-control-rounded" {...register("applicant_job_phone", { required: "Nomor Telepon wajib diisi" })} type="text" placeholder="Nomor Telepon" />
                                            {errors.applicant_job_phone?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_phone?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_nickname">Nama Panggilan <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className="form-control form-control-rounded" {...register("applicant_job_nickname", { required: "Jenis Kelamin wajib diisi" })} type="text" placeholder="Nama Panggilan" />
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_gender">Jenis Kelamin <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <select className='form-control form-control-rounded' {...register("applicant_job_gender", { required: "Jenis Kelamin wajib diisi" })}>
                                                <option value="Laki-Laki">Laki-Laki</option>
                                                <option value="Perempuan">Perempuan</option>
                                            </select>
                                            {errors.applicant_job_gender?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_gender?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_pob">Tempat Kelahiran <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className="form-control form-control-rounded" {...register("applicant_job_pob", { required: "Tempat Kelahiran wajib diisi" })} type="text" placeholder="Tempat Kelahiran" />
                                            {errors.applicant_job_pob?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_pob?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_dob">Tanggal Lahir <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className="form-control form-control-rounded" {...register("applicant_job_dob", { required: "Tanggal Lahir wajib diisi" })} type="date" />
                                            {errors.applicant_job_dob?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_dob?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_marital_status">Status Perkawinan <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <select className='form-control form-control-rounded' {...register("applicant_job_marital_status", { required: "Status Perkawinan wajib diisi" })}>
                                                <option value="Belum Kawin">Belum Kawin</option>
                                                <option value="Kawin">Kawin</option>
                                            </select>
                                            {errors.applicant_job_marital_status?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_marital_status?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_citizenship">Kewarganegaraan <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <select className='form-control form-control-rounded' {...register("applicant_job_citizenship", { required: "Kewarganegaraan wajib diisi" })}>
                                                <option value="WNI">WNI</option>
                                                <option value="WNA">WNA</option>
                                            </select>
                                            {errors.applicant_job_citizenship?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_citizenship?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_address">Alamat KTP <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <textarea className='form-control form-control-rounded' {...register("applicant_job_address", { required: "Alamat KTP wajib diisi" })}></textarea>
                                            {errors.applicant_job_address?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_address?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_current_address">Alamat Saat Ini <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <textarea className='form-control form-control-rounded' {...register("applicant_job_current_address", { required: "Alamat Saat Ini wajib diisi" })}></textarea>
                                            {errors.applicant_job_current_address?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_current_address?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_apply_via">Informasi Lowongan Ini Dari <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className="form-control form-control-rounded" {...register("applicant_job_apply_via", { required: "Informasi Lowongan wajib diisi" })} type="text" placeholder="Mengetahuai Lowongan Ini Dari" />
                                            {errors.applicant_job_apply_via?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_apply_via?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_work_exp_year">Pengalaman Kerja (Tahun) <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className="form-control form-control-rounded" {...register("applicant_job_work_exp_year", { required: "Pengalaman Kerja wajib diisi" })} type="text" placeholder="Pengalaman Kerja (Tahun)" />
                                            {errors.applicant_job_work_exp_year?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_work_exp_year?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_cv">CV <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className='form-control form-control-rounded' type="file" accept=".pdf,.jpg,.jpeg,.doc,.docx" {...register("applicant_job_cv", { required: "CV wajib diisi" })}></input>
                                            {errors.applicant_job_cv?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_cv?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_advantages">Kelebihan Pelamar <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <textarea className='form-control form-control-rounded' {...register("applicant_job_advantages", { required: "Kelebihan Pelamar wajib diisi" })}></textarea>
                                            {errors.applicant_job_advantages?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_advantages?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_deficiency">Kekurangan Pelamar <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <textarea className='form-control form-control-rounded' {...register("applicant_job_deficiency", { required: "Kekurangan Pelamar wajib diisi" })}></textarea>
                                            {errors.applicant_job_deficiency?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_deficiency?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row m-3">
                                        <h5 className="col-md-3 col-form-label" htmlFor="applicant_job_expect_salary">Ekspektasi Gaji <span className='text-danger'>*</span></h5>
                                        <div className="col-md-9">
                                            <input className="form-control form-control-rounded" {...register("applicant_job_expect_salary", { required: "Ekspektasi Gaji wajib diisi" })} type="text" placeholder="Ekspektasi Gaji" />
                                            {errors.applicant_job_expect_salary?.type === 'required' && <p className='text-danger mt-1' role="alert">{errors.applicant_job_expect_salary?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 mt-4 text-center">
                                            {!register_loading ?
                                                <>
                                                    <button className='btn btn-md btn-info btn-rounded' type='submit'>Kirim Lamaran</button>
                                                </>
                                                :
                                                <button className='btn btn-md btn-info btn-rounded' type='submit' disabled>Loading...</button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='flex-grow-1'></div>
            <Footer />
            <ToastContainer />
        </>
    );
}

export default index;