import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import JobData from './Job';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  jobData: JobData,
});

export default rootReducer;
