import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE } from "../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return (dispatch) => {
    const blacklistWord = [
      "Network Error",
      "No status text available"
    ];
    let validShow = true;

    for (const bw of blacklistWord) {
      if (typeof error === 'string' && error.includes(bw)) {
        validShow = false;
        break;
      }
    }

    if (typeof error === 'string' && error.includes('received score')) {
      error = 'Network Error, silakan ganti jaringan internet Anda.';
      validShow = true;
    }

    if (validShow) {
      dispatch({type: FETCH_ERROR, payload: error})
    }
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const setError = (error) => {
  return (dispatch) => {
    const response = error.request;
    const responseText = response && response.responseText;

    let msg = error && error.hasOwnProperty('message') ? error.message : 'Error!';
    try {
      const resJson = JSON.parse(responseText);
      msg = resJson && resJson.hasOwnProperty('message') ? resJson.message : msg;
    } catch(e) {
      if (responseText) {
        msg = responseText;
      } else {
        msg = response && response.hasOwnProperty('statusText') ? response.statusText : msg;
      }
    }

    dispatch(fetchError(msg));
  }
}