import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '../views/Home';
import Detail from '../views/Detail';
import Register from '../views/Register';
import NotFound from '../views/errors/NotFound';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Router(props) {
    return (
        <div className='main-content-wrap d-flex flex-column'>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/detail/:slug' element={<Detail />} />
                <Route path='/register/:slug' element={<Register />} />
                <Route path='*' element={<NotFound/>} />
            </Routes>
            {/* <Footer /> */}
        </div>
    );
}

export default Router;