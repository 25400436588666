/* eslint-disable import/no-anonymous-default-export */
import {
    JOB_LOADING,
    JOB_SEARCH,
    JOB_SORTER,
    JOB_FILTERS,
    JOB_POSITIONS,
    JOB_PAGE,
    JOB_ROWS_PER_PAGE,
    JOB_TOTAL,
    JOB_DATA,
    JOB_DETAIL,
    JOB_DETAIL_LOADING,
    JOB_REGISTER_STATUS,
    JOB_REGISTER_STATUS_VISIBILITY,
    JOB_REGISTER_MESSAGE,
    JOB_REGISTER_LOADING,
  } from '../../constants/ActionTypes';
  
  const INIT_STATE = {
    loading: false,
    search: null,
    sorter: 'job_created_at|desc',
    filters: [],
    positions: null,
    page: 1,
    rows: 12,
    total: 0,
    data: null,
    detail: null,
    detail_loading: false,
    register_status: '',
    register_status_visibility: false,
    register_message: '',
    register_loading: false,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case JOB_LOADING: {
        return {...state, loading: action.payload ? true : false};
      }
  
      case JOB_SORTER: {
        return {...state, sorter: action.payload};
      }
  
      case JOB_FILTERS: {
        return {...state, filters: action.payload};
      }

      case JOB_POSITIONS: {
        return {...state, positions: action.payload};
      }
  
      case JOB_SEARCH: {
        return {...state, search: action.payload};
      }
  
      case JOB_PAGE: {
        return {...state, page: action.payload};
      }
  
      case JOB_ROWS_PER_PAGE: {
        return {...state, rows: action.payload};
      }
  
      case JOB_TOTAL: {
        return {...state, total: action.payload ? parseInt(action.payload) : 0};
      }
  
      case JOB_DATA: {
        return {...state, data: action.payload};
      }
  
      case JOB_DETAIL: {
        return {...state, detail: action.payload};
      }
  
      case JOB_DETAIL_LOADING: {
        return {...state, detail_loading: action.payload ? true : false};
      }

      case JOB_REGISTER_STATUS: {
        return {...state, register_status: action.payload ? true : false};
      }

      case JOB_REGISTER_STATUS_VISIBILITY: {
        return {...state, register_status_visibility: action.payload ? true : false};
      }

      case JOB_REGISTER_MESSAGE: {
        return {...state, register_message: action.payload ? true : false};
      }

      case JOB_REGISTER_LOADING: {
        return {...state, register_loading: action.payload ? true : false};
      }
  
      default:
        return state;
    }
  };
  