import Config from './Config';
import Mustache from 'mustache';

const {JOINX_DOMAIN} = Config;

const RouteAccess = {
  getApi: function (name, object) {
    if (this.hasOwnProperty(name)) {
      return Mustache.render(this[name], object);
    }
    return null;
  },
  GET_ALL_JOBS: JOINX_DOMAIN + '/api/JobFair/allJobs',
  GET_JOB_DETAIL: JOINX_DOMAIN + '/api/JobFair/detail',
  POST_APPLY_JOB: JOINX_DOMAIN + '/api/JobFair/applyJob',
  GET_ALL_POSITIONS: JOINX_DOMAIN + '/api/JobFair/allPositions',
};

export default RouteAccess;
