/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Footer from '../../components/Footer';
import { dateFormatter } from '../../util/helpers';
import {
    addFilterJobList,
    getJobList,
    getPositionsJob,
    resetFilterjobData,
} from '../../redux/actions';

function index() {
    const dispatch = useDispatch();
    const { loading, data, positions, page, rows, total } = useSelector(
        ({ jobData }) => jobData,
    );

    const [position, setPosition] = useState('all');
    const [search, setSearch] = useState('');

    const handleSearch = () => {
        dispatch(resetFilterjobData());

        if (position !== 'all') {
            dispatch(addFilterJobList({
                field: 'job_position_id',
                op: 'equal',
                value: position
            }))
        }

        if (search) {
            dispatch(addFilterJobList({
                field: 'job_title',
                op: 'contains',
                value: search
            }))
        }

        dispatch(getJobList());
    }

    const handleReset = () => {
        setPosition('all');
        setSearch('');
        dispatch(resetFilterjobData());
        dispatch(getJobList());
    }

    useEffect(() => {
        dispatch(getJobList());
        dispatch(getPositionsJob());
    }, []);

    return (
        <>
            <div className='main-content'>
                <div className="breadcrumb">
                    <h1>Kesempatan Berkarir di Inixindo Jogja</h1>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-3 col-sm-12'>
                            <div className="card mb-5">
                                <div className="card-body">
                                    <div className="d-flex flex-column">
                                        {positions == null ?
                                            <div className='col-md-12 text-center'>
                                                <div className='spinner spinner-info'></div>
                                            </div>
                                            : <>
                                                <div className='col-md-12'>
                                                    <div className="card-title">Posisi Pekerjaan</div>
                                                    <label className="radio radio-info">
                                                        <input
                                                            type="radio"
                                                            name='category'
                                                            value="all"
                                                            onChange={() => setPosition('all')}
                                                            checked={'all' === position} />
                                                        <span>Semua Posisi</span>
                                                        <span className="checkmark">
                                                        </span>
                                                    </label>
                                                    {positions.map((row, index) => {
                                                        return (
                                                            <label className="radio radio-info" key={index}>
                                                                <input
                                                                    type="radio"
                                                                    name='category'
                                                                    value={row.position_id}
                                                                    onChange={() => setPosition(row.position_id)}
                                                                    checked={row.position_id === position} />
                                                                <span>{row.position_name}</span>
                                                                <span className="checkmark">
                                                                </span>
                                                            </label>
                                                        );
                                                    })}
                                                </div>
                                                <div className="form-group mt-3">
                                                    <input className="form-control form-control-rounded" id="keyword" type="text" placeholder="Cari pekerjaan impian kamu..." value={search} onChange={(e) => setSearch(e.target.value)} />
                                                </div>
                                                <div className='col-md-12 text-center'>
                                                    <button className="btn btn-info btn-rounded col-md-5 m-1" onClick={handleSearch}>Cari Sekarang</button>
                                                    <button className="btn btn-outline-info btn-rounded col-md-5 m-1" onClick={handleReset}>Reset Filter</button>
                                                </div>
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12">
                            <div className='row'>
                                {loading &&
                                    <div className='col-md-12 text-center'>
                                        <div className='spinner spinner-info'></div>
                                    </div>}
                                {data && data.length == 0 && <div className='col-md-12 text-center'>
                                        <div className='col-md-6 offset-md-3 text-center'>
                                            <p>Maaf, saat ini belum tersedia informasi mengenai lowongan kerja. Silakan tetap pantau atau kunjungi situs web resmi perusahaan atau platform pencarian kerja untuk mendapatkan update terbaru terkait lowongan pekerjaan yang mungkin sesuai dengan kebutuhan Anda.</p>
                                        </div>
                                    </div>}
                                {data && data.length > 0 && data.map((row, index) => {
                                    return (
                                        <div className="col-md-4 col-sm-12 mb-3" key={index}>
                                            <div className='card jobs-card'>
                                                <div className="card-body text-center">
                                                    <div className="card-title jobs-title">{row.job_title}</div>
                                                    <div className='jobs-desc-div'>
                                                        <p className='card-text jobs-desc'>
                                                            {row.job_sort_desc}
                                                        </p>
                                                    </div>
                                                    <div className='mb-2'>
                                                        <span className='text-secondary'>
                                                            <i className='i-Calendar-4 mr-2'></i>
                                                            {`Ditutup Pada ${dateFormatter(row.job_closed_at)}`}
                                                        </span>
                                                    </div>
                                                    <NavLink className="btn btn-outline-info btn-sm m-1" to={`/detail/${row.job_slug}`}>Lihat Selengkapnya</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex-grow-1'></div>
            <Footer />
        </>
    );
}

export default index;