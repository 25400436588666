const IS_PRODUCTION = true;
const Config = {
  APP_VERSION: 1.0,
  PRODUCTION: IS_PRODUCTION,
  JOINX_DOMAIN: IS_PRODUCTION
    ? 'https://api.eduparx.id/x3'
    : 'https://joinx.dev.siapkampusmerdeka.id',
  BASENAME_URL_PREFIX: null,
};

export default Config;
