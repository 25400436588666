import axios from 'axios';
import {
    JOB_LOADING,
    JOB_SORTER,
    JOB_FILTERS,
    JOB_POSITIONS,
    JOB_SEARCH,
    JOB_PAGE,
    JOB_ROWS_PER_PAGE,
    JOB_TOTAL,
    JOB_DATA,
    JOB_DETAIL,
    JOB_DETAIL_LOADING,
} from "../../constants/ActionTypes";
import { setError } from "../Common";
import RouteAccess from "../../../config/RouteAccess";

export const getJobList = () => {
    return (dispatch, getState) => {
        const { jobData } = getState();
        const { page, rows, filters, loading } = jobData;

        if (!loading) {
            let params = {
                page: page,
                rows: rows
            };

            let filterRules = [];

            if (filters && filters.length > 0) {
                filters.map((row) => {
                    filterRules.push({
                        field: row.field,
                        op: row.op,
                        value: row.value
                    });

                    return row;
                });
            }

            params['filterRules'] = JSON.stringify(filterRules);

            dispatch(jobLoading(true));
            axios
                .get(`${RouteAccess.GET_ALL_JOBS}`, { params })
                .then(res => {
                    const response = res && res.data ? res.data : {};
                    const { data, total } = response;

                    console.log(response);

                    dispatch({ type: JOB_DATA, payload: data });
                    dispatch({ type: JOB_TOTAL, payload: total });
                    dispatch(jobLoading(false));
                })
                .catch(error => {
                    console.log(error);
                    dispatch(jobLoading(false));
                    dispatch(setError(error));
                });
        }
    };
};

export const getDetailJob = (slug) => {
    return (dispatch, getState) => {
        const { jobData } = getState();
        const { detail_loading } = jobData;

        if (!detail_loading) {
            dispatch(jobDetailLoading(true));
            axios
                .get(`${RouteAccess.GET_JOB_DETAIL}/${slug}`)
                .then(res => {
                    const response = res && res.data ? res.data : {};
                    const { data } = response;

                    dispatch({ type: JOB_DETAIL, payload: data });
                    dispatch(jobDetailLoading(false));
                })
                .catch(error => {
                    console.log(error);
                    dispatch(jobDetailLoading(false));
                    dispatch(setError(error));
                });
        }
    };
};

export const getPositionsJob = () => {
    return (dispatch, getState) => {
        const { jobData } = getState();
        const { positions } = jobData;

        if (positions == null) {
            axios
                .post(`${RouteAccess.GET_ALL_POSITIONS}`)
                .then(res => {
                    const response = res && res.data ? res.data : {};
                    const { data } = response;

                    dispatch({ type: JOB_POSITIONS, payload: data });
                })
                .catch(error => {
                    console.log(error);
                    dispatch(setError(error));
                });
        }
    };
};

export const jobLoading = isLoading => {
    return dispatch => {
        dispatch({ type: JOB_LOADING, payload: isLoading ? true : false });
    };
};

export const jobDetailLoading = isLoading => {
    return dispatch => {
        dispatch({ type: JOB_DETAIL_LOADING, payload: isLoading ? true : false });
    };
};

export const handleJobPagination = (pageNumber, pageSize) => {
    return (dispatch) => {
        dispatch({ type: JOB_PAGE, payload: pageNumber });
        dispatch({ type: JOB_ROWS_PER_PAGE, payload: pageSize });
        dispatch(getJobList());
    };
}

export const sortJobList = (value) => {
    return (dispatch) => {
        dispatch({ type: JOB_SORTER, payload: value });
    }
}

export const addFilterJobList = (value) => {
    return (dispatch, getState) => {
        const { jobData } = getState();
        const { filters } = jobData;

        let newFilters = filters;
        newFilters.push(value);

        dispatch({ type: JOB_FILTERS, payload: newFilters });
    }
}

export const resetFilterjobData = () => {
    return (dispatch) => {
        dispatch({ type: JOB_FILTERS, payload: [] });
        dispatch({ type: JOB_SEARCH, payload: null });
    }
}