import $ from "jquery";

export const moneyFormat = (number) => {
    if (isNaN(number)) return "";
    const str = String(number);
    let result = "";
    const len = str.length;
    for (let i = len - 1; i >= 0; i--) {
        if ((i + 1) % 3 === 0 && i + 1 !== len) result += ".";
        result += str.charAt(len - 1 - i);
    }
    return result;
}

export const bytesToSize = (bytes) =>
{
	let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const getBase64 = (file) =>
{
	return new Promise((resolve, reject) =>
	{
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => resolve(reader.result);
	    reader.onerror = error => reject(error);
	});
}

export const getImageResolutionInfo = (originFileObj) =>
{
	return new Promise((resolve, reject) => {
		let _URL = window.URL || window.webkitURL;
	    let img = new Image();
	    let objectUrl = _URL.createObjectURL(originFileObj);
	    img.onload = function(){
	    	resolve({
	    		width: this.width,
	    		height: this.height
	    	});
	        _URL.revokeObjectURL(objectUrl);
	    };
	    img.src = objectUrl;
	});
}

export const stripHtml = (html, removeLineBreak) =>
{
   	let tmp = document.createElement("div");
   	tmp.innerHTML = html;
   	setTimeout(() => {
   		tmp.remove();
   	}, 500);
   	let content = tmp.textContent || tmp.innerText || "";
   	if (removeLineBreak) content = content.replace(/(\r\n|\n|\r)/gm, "");
   	return content;
}

export const toQueryString = (object) =>
{
  	let str = [];
  	for (let key in object)
  	{
    	if (object.hasOwnProperty(key)) str.push(encodeURIComponent(key) + "=" + encodeURIComponent(object[key]));
    }
    let val = `?${str.join("&")}`;
  	if (val.trim() === '?') return '';
  	return val;
}

export const isVisibleInScroll = (scrollableElement, element, partial) =>
{
    let container = $(scrollableElement);
    let contHeight = container.height();
    // let contTop = container.scrollTop();
    // let contBottom = contTop + contHeight ;

    let elemTop = $(element).offset().top - container.offset().top;
    let elemBottom = elemTop + $(element).height();

    let isTotal = (elemTop >= 0 && elemBottom <= contHeight);
    let isPart = ((elemTop < 0 && elemBottom > 0 ) || (elemTop > 0 && elemTop <= container.height())) && partial;

    return  isTotal  || isPart ;
}

export const capitalizeFirstLetter = (str) => {
	var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
}

export const durationFormatter = (duration) => {
	const hours = Math.floor(duration / 60);
  	const minute = duration % 60;

	return hours >= 1 ? `${hours} Jam ${minute} Menit` : `${minute} Menit`;
}

export const removeWordContainNumber = (str) => {
	return str.replace(/[a-z]*[^\s]+\d+[^\s]+[a-z]*/gi, '').trim();
}

export const breadcrumbFormat = (str) => {
	if (str.includes("-")) {
		const uncapitalizedStr = str.replace(/-/g, " ");
		const removedWordContainNumberStr = removeWordContainNumber(uncapitalizedStr);
		return capitalizeFirstLetter(removedWordContainNumberStr);
	}

	const removedWordContainNumberStr = removeWordContainNumber(str);
	return removedWordContainNumberStr.replace(/([A-Z])/g, ' $1').trim();
}

export const dateFormatter = (rawDate) => {
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  	
	const [date, time] = rawDate.split(' ');

	const dateOfMonth = date.split('-')[2];
	const month = months[date.split('-')[1] - 1];
	const year = date.split('-')[0];
	const hour = time.slice(0, 5);

  	return `${dateOfMonth} ${month} ${year} ${hour}`;
}