// Job
export const JOB_LOADING = 'JOB_LOADING';
export const JOB_SEARCH = 'JOB_SEARCH';
export const JOB_SORTER = 'JOB_SORTER';
export const JOB_FILTERS = 'JOB_FILTERS';
export const JOB_POSITIONS = 'JOB_POSITIONS';
export const JOB_PAGE = 'JOB_PAGE';
export const JOB_ROWS_PER_PAGE = 'JOB_ROWS_PER_PAGE';
export const JOB_TOTAL = 'JOB_TOTAL';
export const JOB_DATA = 'JOB_DATA';
export const JOB_DETAIL = 'JOB_DETAIL';
export const JOB_DETAIL_LOADING = 'JOB_DETAIL_LOADING';
export const JOB_REGISTER_STATUS = 'JOB_REGISTER_STATUS';
export const JOB_REGISTER_STATUS_VISIBILITY = 'JOB_REGISTER_STATUS_VISIBILITY';
export const JOB_REGISTER_MESSAGE = 'JOB_REGISTER_MESSAGE';
export const JOB_REGISTER_LOADING = 'JOB_REGISTER_LOADING';

//Contact Module const
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';