/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import Footer from '../../components/Footer';

import { getDetailJob } from '../../redux/actions';

function index() {
    const dispatch = useDispatch();
    let { slug } = useParams();

    const { detail, detail_loading } = useSelector(
        ({ jobData }) => jobData,
    );

    useEffect(() => {
        dispatch(getDetailJob(slug));
    }, []);

    return (
        <>
            <div className='main-content'>
                <div className="breadcrumb">
                    <NavLink className="btn btn-outline-info btn-icon btn-sm m-1" to='/'>
                        <span className='ul-btn__icon'>
                            <i className='i-Arrow-Back-2'></i>
                        </span>
                        Kembali
                    </NavLink>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className='offset-1 col-md-10 mb-4'>
                    <div className="card">
                        {detail_loading &&
                            <div className="card-body text-center">
                                <div className='spinner spinner-info'></div>
                            </div>}
                        {detail &&
                            <div className="card-body pt-5 pb-5">
                                <div className='mb-5 ml-4'>
                                    <h2>{detail.job_title}</h2>
                                    <p>{detail.job_sort_desc}</p>
                                </div>
                                {detail.job_desc &&
                                    <div id='job-desc'>
                                        <h4 className='mt-4 ml-4'>Jobdesk</h4>
                                        <div dangerouslySetInnerHTML={{ __html: detail.job_desc }}>
                                        </div>
                                    </div>}
                                {detail.job_qualification &&
                                    <div id='job-qualification'>
                                        <h4 className='mt-4 ml-4'>Kualifikasi</h4>
                                        <div dangerouslySetInnerHTML={{ __html: detail.job_qualification }}>
                                        </div>
                                    </div>}
                                {detail.job_requirement &&
                                    <div id='job-requirement'>
                                        <h4 className='mt-4 ml-4'>Requirement</h4>
                                        <div dangerouslySetInnerHTML={{ __html: detail.job_requirement }}>
                                        </div>
                                    </div>}
                                {detail.job_benefit &&
                                    <div id='job-benefit'>
                                        <h4 className='mt-4 ml-4'>Fasilitas</h4>
                                        <div dangerouslySetInnerHTML={{ __html: detail.job_benefit }}>
                                        </div>
                                    </div>}
                                {detail.job_note &&
                                    <div id='job-note'>
                                        <h4 className='mt-4 ml-4'>Catatan</h4>
                                        <div dangerouslySetInnerHTML={{ __html: detail.job_note }}>
                                        </div>
                                    </div>}
                                <div className='mt-5 text-center'>
                                    <NavLink className="btn btn-md btn-info btn-rounded" to={`/register/${slug}`} >Lamar Sekarang</NavLink>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='flex-grow-1'></div>
            <Footer />
        </>
    );
}

export default index;