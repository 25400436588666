import React from 'react';
import { NavLink } from 'react-router-dom';

function NotFound(props) {
    return (
        <div className="not-found-wrap text-center">
            <h1 className="text-60">404</h1>
            <p className="text-36 subheading mb-3">Error!</p>
            <p className="mb-5 text-muted text-18">Ups! Halaman tidak tersedia.</p>
            <NavLink className="btn btn-lg btn-info btn-rounded" to="/" >Kembali ke halaman utama</NavLink>
        </div>
    );
}

export default NotFound;