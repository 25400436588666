import React from "react";
import { Provider } from "react-redux";
import "./assets/styles/css/themes/lite-purple.min.css";

import Router from "./router";
import { Store } from './redux/store';
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="app-admin-wrap layout-sidebar-large">
      <Provider store={Store}>
        <Navbar />
        <Router history />
      </Provider>
    </div>
  );
}

export default App;
