/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Logo from '../assets/images/inix-logo.png';

function Footer({ children }) {
    return (
        <div className="app-footer">
            <div className="footer-bottom d-flex flex-column flex-sm-row align-items-center">
                <div className="logo">
                    <img src={Logo} />
                </div>
                <span className="flex-grow-1"></span>
                <div className="d-flex align-items-center">
                    <div>
                        <p className="m-0">&copy; 2024 PT Inixindo Widya Iswara Nusantara</p>
                        {/* <p className="m-0">All rights reserved</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;