import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';
import Config from '../../config/Config'

const basename = Config.BASENAME_URL_PREFIX;

const createBrowserHistory = require('history').createBrowserHistory;

export const history = createBrowserHistory({basename: basename ? basename : null});

export const Store = createStore(rootReducer(history), applyMiddleware(thunk));
